import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { CustomerModel } from "./customer.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private customer: CustomerModel;

  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    return throwError("An error occurred:", error.error);
  }

  findCustomer(
    customer: string
  ): Observable<{ data: CustomerModel; error: any }> {
    const url = `/api/customer?customer=${customer}`;

    return this.http
      .get<{ data: CustomerModel; error: any }>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  async getCustomer() {
    let result: CustomerModel;
    if (
      this.customer &&
      Object.keys(this.customer).length === 0 &&
      Object.getPrototypeOf(this.customer) === Object.prototype
    ) {
      result = this.customer;
    } else if (sessionStorage.getItem("customer")) {
      result = JSON.parse(sessionStorage.getItem("customer"));
    } else {
      const user = JSON.parse(sessionStorage.getItem("user")).group ?? null;
      const response = await this.findCustomer(user).toPromise();
      result = response.data;
      this.setCustomer(result);
    }

    return result;
  }

  setCustomer(customer: CustomerModel) {
    sessionStorage.setItem("customer", JSON.stringify(customer));
    this.customer = customer;
    return this.customer;
  }

  setOperator(value: string) {
    let current: CustomerModel;
    const operator = value;
    this.getCustomer().then((x) => {
      current = { ...x, operator };
      this.setCustomer(current);
    });
  }
}
