import { Component } from "@angular/core";

import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/telephone/auth/auth.service";
import { CustomerModel } from "src/app/telephone/store/customer/customer.model";
import { CustomerService } from "src/app/telephone/store/customer/customer.service";
import { Router } from "@angular/router";
import { AppRoutingModule } from "src/app/app-routing.module";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent {
  customer$: CustomerModel;
  location: Location;

  title = "";
  user: string;
  public isFleet: boolean = false;
  public isCollapsed = true;

  constructor(
    private router: Router,
    location: Location,
    public toastr: ToastrService,
    private customerService: CustomerService,
    public auth: AuthService,
    public appRouting: AppRoutingModule
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.isFleet = JSON.parse(sessionStorage.getItem("user")).isFleet;
    if (this.location.path().toLowerCase().includes("tele")) {
      this.title = "TeleGenie";
      this.customerService
        .getCustomer()
        .then((x: { company: string }) => (this.user = x.company));
    } else if (this.location.path().toLowerCase().includes("fleet")) {
      this.title = "FleetGenie";
      this.customerService
        .getCustomer()
        .then((x: { company: string }) => (this.user = x.company));
    } else {
      this.title = "CloudGenie";
      this.user = "Opex Genie";
    }
  }
  goto() {
    this.router.navigateByUrl("/tele/genie-dashboard");
  }

  gotoRoute(route: string) {
    this.router.navigateByUrl(route);
  }

  signOut() {
    this.auth.signOut();
  }
}
